import { render, staticRenderFns } from "./CardBanner.vue?vue&type=template&id=4be4ddcb&scoped=true"
import script from "./CardBanner.vue?vue&type=script&lang=js"
export * from "./CardBanner.vue?vue&type=script&lang=js"
import style0 from "./CardBanner.vue?vue&type=style&index=0&id=4be4ddcb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4be4ddcb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,MoleculeButton: require('/home/userapp/components/Molecule/Button/Button.vue').default,AtomIcon: require('/home/userapp/components/Atom/Icon.vue').default,AtomLink: require('/home/userapp/components/Atom/Link.vue').default})
