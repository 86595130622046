
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    formId: { type: String, required: true },
  },
  mounted() {
    const script = document.createElement('script')
    script.charset = 'utf-8'
    script.type = 'text/javascript'
    script.src = 'https://js-eu1.hsforms.net/forms/embed/v2.js'
    script.onload = () => {
      // eslint-disable-next-line no-undef
      hbspt.forms.create({
        portalId: '143918352',
        formId: this.formId,
        target: '#hubspot-form',
      })
    }
    document.body.appendChild(script)
  },
})
